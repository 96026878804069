$preloader-size: 20px;
$preloader-width: 2px;

.preloader {
  background: transparent;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
  .circle {
    animation: spin 0.5s linear infinite;
    border: $preloader-width solid transparentize($color: #FFFFFF, $amount: 0);
    border-bottom-color: transparentize($color: #FFFFFF, $amount: 0.8);
    border-radius: 50%;
    height: $preloader-size;
    left: 50%;
    margin-left: -($preloader-size + $preloader-width) / 2;
    margin-top: -($preloader-size + $preloader-width) / 2;
    position: absolute;
    top: 50%;
    transition: all 0.7s ease-in-out;
    width: $preloader-size;
  }
  // display: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
