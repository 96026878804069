@import './css/generic/generic_resets.scss';
@import './css/fancy_hover.scss';
@import './scss/preloader';

@font-face {
	font-family: 'LexiconTTW No2';
    src: url('./fonts/LexiconTTWNo2-RomanA.eot');
    src: url('./fonts/LexiconTTWNo2-RomanA.eot?#iefix') format('embedded-opentype'),
         url('./fonts/LexiconTTWNo2-RomanA.woff') format('woff'),
         url('./fonts/LexiconTTWNo2-RomanA.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal; 
}

html, body{
    height:100%;
    background-color: #d3dbe0;
}

body{
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

canvas{
    display: block;
}

.fancy-hover-white {
    @include fancy-hover(#faf9f7, 1px, .3s);
}

.logo{
    position: absolute;
    left:0px;
    top:0px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 147px;
    background-image: url("./assets/logo.svg");

    opacity:0;
    // pointer-events: all;
    // cursor: pointer;
}

.logo_white{
    opacity: 0;
    background-image: url("./assets/logo_white.svg");
}

.container{
    position: fixed;
    margin: 100px;
    top:0px;
    left:0px;
    width: calc(100% - 200px);
    height: calc(100% - 200px);
    pointer-events: none;
    z-index:2;
    // display: none;
}

.cookies_container{
    position: absolute;
    top: 0px;
    overflow: auto;
    width: 100%;
    height: 100%;
    // opacity: 0;
    // visibility: hidden;
    background-color: rgba(255, 255, 255, 0.5);
}

.video_texture{
    position: fixed;
    visibility: hidden;
    top:0px;
}

.close_cookies{
    position: absolute;
    height: 21px;
    width: 21px;
    right: 40px;
    top: 40px;
    background-image: url("./assets/close.svg");
    cursor: pointer;
}

.cookies-policy-body-subtitle {
    display: inline-block;
    color: #263843;
    font-family: trade-gothic-next;
    font-size: 14px;
    letter-spacing: .9px;
    line-height: 20.48px;
    margin-top: 35px;
    margin-bottom: 15px;
}

.cookies-policy-form{
    margin-top: 10px;
    //   display: flex;
    label {
        position: relative;
        max-width: 359px;
        color: #4F4F4F;
        font-family: "LexiconTTW No2";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19.6px;
        padding-left: 50px;
        display: inline-block;
        user-select: none;


        &::before{
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            border: 1px solid #263843;
            width: 11px;
            height: 11px;
            visibility: visible;
        }

        &::after {
            position: absolute;
            top: 4px;
            left: 2px;
            width: 7px;
            height: 7px;
            font-weight: bold;
            background-color: #263843;
        }
    }
}

#cookies-policy-checkbox{
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}
#cookies-policy-checkbox:checked+label:after{
    content: '';
}

input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

.button{
    position: relative;
    bottom:0px;
    left:0px;
    width: 270px;
    height: 40px;
    border: 0.8px solid rgb(46, 66, 78);
    border-radius: 4px;

    color: rgb(38, 56, 67);
    font-family: trade-gothic-next, sans-serif;
    letter-spacing: 0px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    margin-top:30px;
    margin-right: 100px;

    float: left;
    cursor: pointer;
    pointer-events: all;
    opacity:0;
    visibility: hidden;
}

.footer{
    position: absolute;
    bottom:0px;
    right:0px;
    color: #263843;
    font-size: 14px;
    line-height: 43px;
}

.privacy{
    position: relative;
    right:0px;
    font-family: trade-gothic-next, sans-serif;
    font-weight:400;
    font-style:normal;
    letter-spacing: 0px;
    text-align: left;
    // text-transform: uppercase;
    display: inline-block;
    //margin-top:30px;
    //line-height: 43px;
    cursor: pointer;
    pointer-events: all;
    margin-right:40px;
    opacity:0;
    visibility: hidden;
}

.link {
    color: #263843;
    opacity:0.5;
}

.copyrights{
    position: relative;
    right:0px;

    font-family: trade-gothic-next, sans-serif;
    font-weight:400;
    font-style:normal;
    letter-spacing: 0px;
    text-align: left;
    // text-transform: uppercase;
    display: inline-block;
    //margin-top:30px;
    //line-height: 40px;
    //cursor: pointer;
    //pointer-events: all;
    opacity:0;
    visibility: hidden;
}

.copy{
    opacity:0.5;
}

.card_exp{
    position: absolute;
    display: none;
    right:0px;
    top:0px;
    color: #263843;
    font-family: trade-gothic-next, sans-serif;
    font-size:14px;
    font-weight:400;
    font-style:normal;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 0px;
    line-height: 30px;
    cursor: pointer;
    pointer-events: all;
    opacity:0;
    visibility: hidden;
}

.wishes{
    position: absolute;
    color: rgb(38, 56, 67);
    bottom:0px;
    left:0px;
    font-size: 52px;
    font-family: "LexiconTTW No2";
    line-height: 1.2;
}

.wish{
    position: absolute;
    opacity: 0;
    bottom: 0px;
    width: 600px;
}

.chinese{
    font-family: source-han-serif-sc,serif;
    font-weight: 400;
    font-style: normal;
}

.water_fund_overlay{
    position: absolute;
    right: 100px;
    width: 480px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.water_header{
    position: relative;
    color: #DFE5E9;
    font-family: "LexiconTTW No2";
    font-size: 56px;
    letter-spacing: 0;
    line-height: 1.5;
    margin-bottom:1.5em;
    opacity:0;
    width:100%;
}

.water_description{
    position: relative;
    width: 300px;
    color: #C1C8CD;
    font-family: "LexiconTTW No2";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    opacity:0;
}

.navigation_hints{
    position: absolute;
    bottom:0px;
    opacity: 0;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_container{
    // position: absolute;
    // bottom: 40px;
    // width: 200px;
    // margin-left: -100px;
    // left:50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scroll{

}

.drag{

}

.click{

}

.icon{
    position: relative;
    height: 34px;
    width: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/mouse.svg");
    display: inline-block;
    opacity:0;
}

.icon_button{
    position: absolute;
    top:0px;
    height: 34px;
    width: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/mouse_button.svg");
    display: inline-block;
}

.icon_scroll{
    position: absolute;
    top:0px;
    height: 34px;
    width: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/mouse_scroll.svg");
    display: inline-block;
}

.logo_centered{
    left: 50%;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
}

.icon_description{
    position: relative;
    display: inline-block;
    // width: 140px;
    opacity: 0.5;
    color: #263843;
    font-family: trade-gothic-next, sans-serif;
    font-size: 14px;
    letter-spacing: 0.45px;
    line-height: 43px;
    text-align: center;
    // margin-top:10px;
}

p{
    margin-bottom:1em;
}

body.pointer * { cursor: pointer; }

$break-mobile-tight: 400px;
$break-mobile: 576px;
$break-small: 768px;
$break-md: 1024px;
$break-large: 1366px;
$break-x-large: 1680px;

.icon_touch{
    position: absolute;
    top:0px;
    height: 34px;
    width: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/tap.svg");
    display: inline-block;
    opacity:0;
}

.icon_touch_2{
    position: absolute;
    top:0px;
    height: 34px;
    width: 38px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/pinch.svg");
    display: inline-block;
    opacity:0;
}

.icon_touch_points{
    position: absolute;
    top:0px;
    height: 34px;
    width: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/tap_points.svg");
    display: inline-block;
    opacity:0;
}

.icon_touch_2_points{
    position: absolute;
    top:0px;
    height: 34px;
    width: 38px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/pinch_points.svg");
    display: inline-block;
    opacity:0;
}

.cookies_overlay{
    position: absolute;
    background-color: #FFFFFF;

    margin: 100px;

    padding-top: 8.33333%;
    padding-bottom: 8.33333%;
}

.col-left{
    float: left;
    width: 25%;
    margin-left: 8.33333%;
}

.col-right{
    float: left;
    width: 41.66667%;
    margin-left: 8.33333%;
}

h2 {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 36px;
    -webkit-font-smoothing: antialiased;
    text-align: start;
    color: #4d4d4d;
    font-style: normal;
    font-weight: 400;
    font-family: LexiconTTW No2;
}

.file-link, .link-indented, body, p {
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 27px;
    text-align: start;
    color: #4d4d4d;
    font-style: normal;
    font-weight: 400;
    font-family: LexiconTTW No2;
}

.cookies-policy-list-title {
    margin-top: 50px;
    margin-bottom: 30px;
    color: #2f2f2f;
    font-family: LexiconTTW No2;
    font-size: 22px;
    letter-spacing: .15px;
    line-height: 28.6px;
}

.small_cookies{
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    max-width: 316px;
    background-color: #FFFFFF;
    opacity: 0;
    visibility: hidden;
    z-index:4;
    
    a {
        color:#2f2f2f;
    }
}

.small_cookies_container{
    margin: 70px 46px 70px 46px;
}

.close{
    position: absolute;
    top:30px;
    right: 20px;
    height: 16px;
    width: 16px;
    //transform: rotate(315deg);
    cursor: pointer;
}

.path-1 {
    position: absolute;
    box-sizing: border-box;
    height: 1px;
    width: 16px;
    background-color: #263843;
    transform: rotate(45deg);
}

.path-2 {
    position: absolute;
    box-sizing: border-box;
    height: 1px;
    width: 16px;
    background-color: #263843;
    transform: rotate(135deg);
}

.cookies_text{
    font-family: trade-gothic-next, sans-serif;
    font-size: 12px;
    letter-spacing: 0.75px;
    line-height: 1.6;
    margin-bottom:50px;
}

@media only screen and (max-width: #{$break-x-large - 1px}) {
    .container{
        margin: 80px;
        width: calc(100% - 160px);
        height: calc(100% - 160px);
        pointer-events: none;
    }

    .cookies_overlay{
        margin:80px;
    }

    .wishes{
        font-size: 45px;
        line-height: 1.2;
    }
}

@media only screen and (max-width: #{$break-large - 1px}) {
    .container{
        margin: 80px;
        width: calc(100% - 160px);
        height: calc(100% - 160px);
        pointer-events: none;
    }

    .cookies_overlay{
        margin:80px;
    }

    .navigation_hints{
        bottom:0px;
        justify-content: flex-end;
    }

    .wishes{
        font-size: 45px;
        line-height: 1.2;
        bottom:60px;
    }

    .footer{
        right:auto;
        left:0px;
    }
}

@media only screen and (max-width: #{$break-md - 1px}) {
    .container{
        margin: 50px;
        width: calc(100% - 100px);
        height: calc(100% - 100px);
        pointer-events: none;
    }

    .cookies_overlay{
        margin:50px;
        padding-top:90px;
    }

    // .navigation_hints{
    //     bottom:0px;
    //     justify-content: flex-end;
    // }
    .wishes{
        font-size: 40px;
        line-height: 1.2;
    }

    .logo{
        width: 120px;
    }

    .privacy{
        display: inline-block;
    }

    .button{
        display: none;
    }

    .card_exp{
        line-height: 23px;
    }
}

@media only screen and (max-width: #{$break-small - 1px}) {
    .container{
        margin: 50px;
        width: calc(100% - 100px);
        height: calc(100% - 100px);
        pointer-events: none;
    }

    .col-left{
        float: left;
        width: 83.3%;
        margin-left: 8.33333%;
    }
    
    .col-right{
        float: left;
        width: 83.3%;
        margin-left: 8.33333%;
    }
    
    .cookies_overlay{
        margin:50px;
        padding-top:90px;
    }

    .wishes{
        font-size: 35px;
        line-height: 1.2;
        bottom:60px;
    }

    .privacy{
        display: block;
    }

    .logo{
        width: 120px;
    }

    .icon_description{
        font-size: 12px;
        line-height:2;
    }

    .footer{
        font-size: 12px;
        line-height:2;
    }

    .card_exp{
        font-size: 12px;
        line-height: 23px;
    }
}

@media only screen and (max-width: #{$break-mobile - 1px}) {
    .container{
        margin: 20px;
        margin-top: 40px;
        width: calc(100% - 40px);
        height: calc(100% - 60px);
        pointer-events: none;
    }

    .cookies_overlay{
        margin:30px 20px 30px 20px;
    }

    .wishes{
        font-size: 30px;
        line-height: 1.2;
        bottom:60px;
    }

    .navigation_hints{
        //display: none;
    }
    .logo{
        width: 110px;
    }
}

@media only screen and (max-width: #{$break-mobile-tight - 1px}) {
    .container{
        margin: 20px;
        margin-top: 40px;
        width: calc(100% - 40px);
        height: calc(100% - 60px);
        pointer-events: none;
    }

    .cookies_overlay{
        margin:30px 20px 30px 20px;
    }
}

.video-fallback {
    position: fixed;
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    overflow: hidden;
    z-index:0;
    display: none;
}

.video-fallback >  #video-fallback {
    position: absolute;
    top: 50%; 
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%; 
    min-height: 100%; 
    width: auto; 
    height: auto;
    z-index: 1; 
    overflow: hidden;
}

@supports (object-fit: cover) {
    .video-fallback > #video-fallback {
        // top: 0; left: 0;
        width: 100%; height: 100%;
        object-fit: cover;
    }
}