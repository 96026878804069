/**
* Fancy underline 
* Makes 1px underline with linear gradient. 
* @param - Unbderline offset from the bottom in pixels.
* @param transitionTime - >0: Underline expands, ==0 - hides, <0 - shrinks.
*/
@mixin fancy-hover ($color, $bottomOffset, $transitionTime) {
    width: calc(100%); /* IE EDGE HACK */
    background-image: linear-gradient(180deg, 
        transparent calc(100% - #{$bottomOffset} - 1px ),
        $color calc(100% - #{$bottomOffset} - 1px),
        $color calc(100% - #{$bottomOffset}),
        transparent calc(100% - #{$bottomOffset})
        );
    background-repeat: no-repeat;
    @if $transitionTime > 0 {
        background-size: 0% 100%;
        transition: background-size $transitionTime;
        
        &:hover {
            background-size: 100% 100%;
        }
    } @else {
        background-size: 100% 100%;
        transition: background-size ($transitionTime * -1);
        &:hover {
            background-size: 0% 100%;
        }
    }
}

/**
* Makes a stroke around text so underline doesn't touch it. 
*/
@mixin underline-shadow-mask ($color) {
    text-shadow: $color -1px -1px,$color -1px 0px,$color 0px 0.5px,$color 1px 0px,$color 1px -1px,$color -2px -2px,$color -2px 0px,$color 0px 1px,$color 2px 0px,$color 2px -2px,$color -3px -3px, $color -3px 0px, $color 0px 1.5px,$color 3px 0px,$color 3px -3px;
}
